.page-container {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.page-content {
  flex-grow: 1;
  max-width: 1200px;
  margin-top: 56px;
  padding: 16px;
  width: 100%;
}

.page-content-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#page-navbar {
  position: fixed;
  width: 100%;
  align-self: stretch;
  z-index: 1000;
  border-bottom: solid 2px rgb(239, 238, 238);
}

#nav-content {
  max-width: 1200px;
  padding: 0px 16px;
  display: flex;
  margin: auto;
  flex-direction: row;
  align-items: center;
  height: 56px;
}
